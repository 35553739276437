<template>
    <b-modal v-model="openedModal" size="lg"  hide-footer scrollable>
        <template #modal-title>
            <h5 v-if="type === 'add'">Dodaj</h5>
            <h5 v-else-if="$hasPermissions($permissions.EditRoles)">Uredi</h5>
            <h5 v-else>Pregled</h5>
        </template>
        <validation-observer ref="validate">
            <b-form>
                <b-form-group label="Ime">
                    <validation-provider #default="{ errors }" name="title" rules="required">
                        <b-form-input v-model="role.title" placeholder="Ime" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-form>
        </validation-observer>

        <div class="font-weight-bold mb-2" style="font-size: 15px;">Pravice</div>
        <b-form-checkbox-group
            id="roleCheckbox"
            v-model="role.permissions"
        >
            <div>
                <div class="font-weight-bold">Nepremičnine</div>
                <div class="row row-cols-2 mx-0 mt-1">
                    <div><b-form-checkbox :value="100" @change="remove(101)"> {{getPermission(100)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(100)" :value="101"> {{getPermission(101)}} </b-form-checkbox></div>
                </div>

                <div class="font-weight-bold mt-2">Novice</div>
                <div class="row row-cols-2 mx-0 mt-1">
                    <div><b-form-checkbox :value="200" @change="remove(101)"> {{getPermission(200)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(200)" :value="201"> {{getPermission(201)}} </b-form-checkbox></div>
                </div>

                <div class="font-weight-bold mt-2">Storitve</div>
                <div class="row row-cols-2 mx-0 mt-1">
                    <div><b-form-checkbox  :value="300" @change="remove(301, 302)"> {{getPermission(300)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(300)" :value="301"> {{getPermission(301)}} </b-form-checkbox></div>
                    <div />
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(300)" :value="302"> {{getPermission(302)}} </b-form-checkbox></div>
                </div>

                <div class="font-weight-bold mt-2">Mali oglasi</div>
                <div class="row row-cols-2 mx-0 mt-1">
                    <div><b-form-checkbox  :value="400" @change="remove(401, 402)"> {{getPermission(400)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(400)" :value="401"> {{getPermission(401)}} </b-form-checkbox></div>
                    <div/>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(400)" :value="402"> {{getPermission(402)}} </b-form-checkbox></div>
                </div>

                <div class="font-weight-bold mt-2">Uporabniki</div>
                <div class="row row-cols-2 mx-0 mt-1">
                    <div><b-form-checkbox @change="remove(501)" :value="500"> {{getPermission(500)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(500)" :value="501"> {{getPermission(501)}} </b-form-checkbox></div>
                    <div class="mt-1"><b-form-checkbox  @change="remove(503, 508)" :value="502"> {{getPermission(502)}} </b-form-checkbox></div>
                    <div  class="mt-1"><b-form-checkbox  :disabled="role && role.permissions && !role.permissions.includes(502)" :value="503"> {{getPermission(503)}} </b-form-checkbox></div>
                    <div/>
                    <div><b-form-checkbox  :disabled="role && role.permissions && !role.permissions.includes(502)" :value="508"> {{getPermission(508)}} </b-form-checkbox></div>
                    <div  class="mt-1"><b-form-checkbox  @change="remove(505, 509)" :value="504"> {{getPermission(504)}} </b-form-checkbox></div>
                    <div  class="mt-1"><b-form-checkbox  :disabled="role && role.permissions && !role.permissions.includes(504)" :value="505"> {{getPermission(505)}} </b-form-checkbox></div>
                    <div/>
                    <div><b-form-checkbox  :disabled="role && role.permissions && !role.permissions.includes(504)" :value="509"> {{getPermission(509)}} </b-form-checkbox></div>
                    <div  class="mt-1"><b-form-checkbox  @change="remove(507)" :value="506"> {{getPermission(506)}} </b-form-checkbox></div>
                    <div  class="mt-1"><b-form-checkbox  :disabled="role && role.permissions && !role.permissions.includes(506)" :value="507"> {{getPermission(507)}} </b-form-checkbox></div>
                </div>

                <div class="font-weight-bold mt-2">Trgovina</div>
                <div class="row row-cols-2 mx-0 mt-1">
                    <div><b-form-checkbox  @change="remove(601,602,603,604)"  :value="600"> {{getPermission(600)}} </b-form-checkbox></div>
                    <div><b-form-checkbox  :disabled="role && role.permissions && !role.permissions.includes(600)" :value="601"> {{getPermission(601)}} </b-form-checkbox></div>
                    <div/>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(600)" :value="602"> {{getPermission(602)}} </b-form-checkbox></div>
                    <div/>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(600)" :value="603"> {{getPermission(603)}} </b-form-checkbox></div>
                    <div/>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(600)" :value="604"> {{getPermission(604)}} </b-form-checkbox></div>
                </div>

                <div class="font-weight-bold mt-2">Oglasna deska</div>
                <div class="row row-cols-2 mx-0 mt-1">
                    <div><b-form-checkbox @change="remove(701)" :value="700"> {{getPermission(700)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(700)" :value="701"> {{getPermission(701)}} </b-form-checkbox></div>
                    <div><b-form-checkbox @change="remove(703)" :value="702"> {{getPermission(702)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(702)" :value="703"> {{getPermission(703)}} </b-form-checkbox></div>
                </div>

                <div class="font-weight-bold mt-2">E-sestanki</div>
                <div class="row row-cols-2 mx-0 mt-1">
                    <div><b-form-checkbox @change="remove(801)" :value="800"> {{getPermission(800)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(800)" :value="801"> {{getPermission(801)}} </b-form-checkbox></div>
                    <div><b-form-checkbox @change="remove(803)" :value="802"> {{getPermission(802)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(802)" :value="803"> {{getPermission(803)}} </b-form-checkbox></div>
                </div>

                <div class="font-weight-bold mt-2">Točke zvestobe</div>
                <div class="row row-cols-2 mx-0 mt-1">
                    <div><b-form-checkbox @change="remove(901)" :value="900"> {{getPermission(900)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(900)" :value="901"> {{getPermission(901)}} </b-form-checkbox></div>
                </div>

                <div class="font-weight-bold mt-2">FAQ</div>
                <div class="row row-cols-2 mx-0 mt-1">
                    <div><b-form-checkbox @change="remove(1001)" :value="1000"> {{getPermission(1000)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(1000)" :value="1001"> {{getPermission(1001)}} </b-form-checkbox></div>
                </div>

                <div class="font-weight-bold mt-2">Urejanje vsebine (Kontakti, O nas, Pogoji varovanje...)</div>
                <div class="row row-cols-2 mx-0 mt-1">
                    <div><b-form-checkbox @change="remove(1101)" :value="1100"> {{getPermission(1100)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(1100)" :value="1101"> {{getPermission(1101)}} </b-form-checkbox></div>
                </div>

                <div class="font-weight-bold mt-2">Vložišče upravnika</div>
                <div class="row row-cols-2 mx-0 mt-1">
                    <div><b-form-checkbox @change="remove(1201)" :value="1200"> {{getPermission(1200)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(1200)" :value="1201"> {{getPermission(1201)}} </b-form-checkbox></div>
                    <div><b-form-checkbox @change="remove(1203)" :value="1202"> {{getPermission(1202)}} </b-form-checkbox></div>
                    <div><b-form-checkbox :disabled="role && role.permissions && !role.permissions.includes(1202)" :value="1203"> {{getPermission(1203)}} </b-form-checkbox></div>
                </div>
            </div>
        </b-form-checkbox-group>

        <div class="d-flex justify-content-end mt-2" v-if="$hasPermissions($permissions.EditRoles)">
            <b-button v-if="type === 'add'" variant="primary" @click="validationFormAdd">Dodaj</b-button>
            <b-button v-else variant="primary" @click="validationFormEdit">Uredi</b-button>
        </div>


    </b-modal>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, PhoneNumber } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import {BFormGroup, BFormInput, BModal, BForm, BFormCheckbox, BFormCheckboxGroup, BButton} from 'bootstrap-vue'

    export default {
        props: {
            type: {
                type: String,
                reqired: true
            }
        },
        computed: {
            roles() {
                return this.$store.getters['permissions/getPermissions']
            }
        },
        components: {
            BButton,
            BFormCheckboxGroup,
            BFormCheckbox,
            ValidationProvider,
            ValidationObserver,
            BFormGroup,
            BFormInput,
            BModal,
            BForm
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                role: {},
                openedModal: false,
                text: 'Dodaj',
                required,
                email,
                PhoneNumber
            }
        },
        methods: {
            remove(...permission) {
                for (const perm of permission) {
                    const index = this.role.permissions.indexOf(perm)
                    if (index !== -1) {
                        this.role.permissions.splice(index, 1)
                    }
                }
            },
            getPermission(id) {
                if (this.roles[id]) {
                    return this.roles[id]
                }

                return ''
            },
            validationFormAdd() {

                this.$refs.validate.validate().then(success => {
                    if (success) {

                        this.addRole()
                    }
                })
            },
            validationFormEdit() {
                this.$refs.validate.validate().then(success => {
                    if (success) {
                        this.editRole()
                    }
                })
            },
            openModal(role) {
                this.role = JSON.parse(JSON.stringify(role))
                if (this.type === 'edit') {
                    this.text = 'Uredi'
                }
                this.openedModal = true
            },
            addRole() {
                const thisIns = this
                const loadPromise = this.$http.put('/api/management/v1/role/', this.role)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Pravica je dodana')
                    thisIns.openedModal = false
                    thisIns.$emit('success')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            editRole() {
                const thisIns = this
                const loadPromise = this.$http.patch(`/api/management/v1/role/${this.role.id}`, this.role)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Admin je urejen')
                    thisIns.openedModal = false
                    thisIns.$emit('success')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        }
    }
</script>
